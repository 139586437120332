/* eslint-disable no-underscore-dangle */
/* eslint-disable react/no-unknown-property */
/* eslint-disable no-useless-escape */
/* eslint-disable no-use-before-define */
/* eslint-disable react/destructuring-assignment */
/* eslint-disable no-unused-vars */
import { useState, useEffect, useCallback } from 'react';
import { Link as RouterLink, useNavigate } from 'react-router-dom';
import PropTypes from 'prop-types';
import {
  Box,
  Button,
  Card,
  CardHeader,
  CircularProgress,
  Dialog,
  DialogActions,
  DialogContent,
  DialogContentText,
  DialogTitle,
  Grid,
  Link,
  Switch,
  Table,
  TableBody,
  TableCell,
  TableHead,
  TableRow,
  TextField,
  Tooltip,
  Typography
} from '@material-ui/core';
import { MobileDatePicker, LocalizationProvider } from '@mui/lab';
import DateAdapter from '@mui/lab/AdapterMoment';
// import { DataGrid } from '@material-ui/data-grid';
import { sessionApi } from '../../../api/sessionApi';
import * as moment from 'moment';
import toast from 'react-hot-toast';
import useMounted from '../../../hooks/useMounted';
import { styled } from '@mui/material/styles';
import LinearProgress, { linearProgressClasses } from '@mui/material/LinearProgress';
import useAuth from '../../../hooks/useAuth';
import * as XLSX from 'xlsx';
import ListIcon from '@material-ui/icons/List';
import { DataGrid, GridActionsCellItem } from '@mui/x-data-grid';
import JSZip from 'jszip';
import { saveAs } from 'file-saver';
import { logApi } from '../../../api/logApi';
import PDFMerger from 'pdf-merger-js';
import AttestationPDF from '../../AttestationPDF';
import { pdf } from '@react-pdf/renderer';
import SendIcon from '@mui/icons-material/Send';
import AttachEmailIcon from '@mui/icons-material/AttachEmail';
import AdjustOutlinedIcon from '@mui/icons-material/AdjustOutlined';
import UploadOutlinedIcon from '@mui/icons-material/UploadOutlined';
import AssignmentIcon from '@mui/icons-material/Assignment';
import { Alert, Chip, ClickAwayListener } from '@mui/material';
import { customerApi } from '../../../api/customerApi';
import { useTheme } from '@emotion/react';

const BorderLinearProgress = styled(LinearProgress)(({ theme }) => ({
  height: 15,
  borderRadius: 7,
  [`&.${linearProgressClasses.colorPrimary}`]: {
    backgroundColor: theme.palette.grey[theme.palette.mode === 'light' ? 200 : 800],
  },
  [`& .${linearProgressClasses.bar}`]: {
    borderRadius: 7,
    backgroundColor: `linear-gradient(90deg, #6fcbb6 ${20}%, #9c64f4 100%)`,
  },
}));

const SessionFacturation = (props) => {
  const { sessionId, session, formation, setShowCheckUserDataAlert, ...other } = props;
  const [rows, setRows] = useState([]);
  moment.locale('fr');
  const mounted = useMounted();
  const navigate = useNavigate();// On utilise navigate afin d'utliser les fonctions de navigation
  const [total, setTotal] = useState(0);
  const { user } = useAuth();
  const [infoFactu, setInfoFactu] = useState({});
  const [honneur, setHonneur] = useState(false);
  const [users, setUsers] = useState([]);
  const [progFactu, setProgFactu] = useState(0);
  const [customers, setCustomers] = useState([]);
  const [alertMessage, setAlertMessage] = useState('');
  const [showAlert, setShowAlert] = useState(false);
  const [openDialogDelete, setOpenDialogDelete] = useState(false);
  const [openCommonDialog, setOpenCommonDialog] = useState(false);
  const [openTooltip, setOpenTooltip] = useState(false);
  const [isLoading, setIsLoading] = useState(false);
  const [currentRowLearnerSessionId, setCurrentRowLearnerSessionId] = useState(null);
  const [dialogText, setDialogText] = useState('');
  const [dialogAction, setDialogAction] = useState('');
  const theme = useTheme();
  const [selectedRows, setSelectedRows] = useState([]);

  
  const downloadAgrement = async (id) => {
    try {
      setIsLoading(true);
      const response = await customerApi.downloadAgrement(id, localStorage.getItem('accessToken'));
      if (response.status === 500) {
        toast.error('Une erreur est survenue lors du téléchargement de la convention !');
      } else if (response.status === 400) {
        toast.error(response.errorMessage);
      } else {
        toast.success('Téléchargement effectué avec succès !');
        getInfoFactu();
      }
      setIsLoading(false);
      setOpenCommonDialog(false);

    } catch (err) {
      console.clear();
      console.error(err);
      setOpenCommonDialog(false);
      setShowCheckUserDataAlert(true);
      setIsLoading(false);
      toast.error('Une erreur est survenue lors du téléchargement !');
    }
  };

  const downloadCerificate = async (id) => {
    try {
      setIsLoading(true);
      const response = await customerApi.downloadCerificate(id, localStorage.getItem('accessToken'));
      if (response.status === 500) {
        toast.error('Une erreur est survenue lors du téléchargemen de l\'attestation !');
      } else if (response.status === 400) {
        toast.error(response.errorMessage);
      } else {
        toast.success('Téléchargement effectué avec succès !');
        getInfoFactu();
      }
      setIsLoading(false);
      setOpenCommonDialog(false);
    } catch (err) {
      console.clear();
      console.error(err);
      setOpenCommonDialog(false);
      setShowCheckUserDataAlert(true);
      setIsLoading(false);
      toast.error('Une erreur est survenue lors du téléchargement !');
    }
  };

  const downloadSwarnStatement = async (id) => {
    try {
      setIsLoading(true);
      const response = await customerApi.downloadSwarnStatement(id, localStorage.getItem('accessToken'));
      if (response.status === 500) {
        toast.error('Une erreur est survenue lors du téléchargemen de l\'attestation !');
      } else if (response.status === 400) {
        toast.error(response.errorMessage);
      } else {
        toast.success('Téléchargement effectué avec succès !');
        getInfoFactu();
      }
      setIsLoading(false);
      setOpenCommonDialog(false);
    } catch (err) {
      console.clear();
      console.error(err);
      setOpenCommonDialog(false);
      setShowCheckUserDataAlert(true);
      setIsLoading(false);
      toast.error('Une erreur est survenue lors du téléchargement !');
    }
  };

  const sendAgreement = async (id) => {
    try {
      setIsLoading(true);
      const response = await customerApi.sendAgreement(id, localStorage.getItem('accessToken'));
      if (response.status === 500) {
        toast.error('Une erreur est survenue lors de l\'envoi de la convention !');
      } else if (response.status === 400) {
        toast.error(response.errorMessage);
      } else {
        toast.success('Convention de formation envoyée avec succès !');
        getInfoFactu();
      }
      setIsLoading(false);
      setOpenCommonDialog(false);
    } catch (err) {
      console.error(err);
      setOpenCommonDialog(false);
      setShowCheckUserDataAlert(true);
      setIsLoading(false);
      toast.error('Une erreur est survenue lors de l\'envoi de la convention !');
    }
  };

  const sendCertificate = async (id) => {
    try {
      setIsLoading(true);
      const response = await customerApi.sendCertificate(id, localStorage.getItem('accessToken'));
      if (response.status === 500) {
        toast.error('Une erreur est survenue lors de l\'envoi de l\'attestation !');
      } else if (response.status === 400) {
        toast.error(response.errorMessage);
      } else {
        toast.success('Attestation de fin de formation envoyée avec succès !');
        getInfoFactu();
      }
      setIsLoading(false);
      setOpenCommonDialog(false);
    } catch (err) {
      console.clear();
      console.error(err);
      toast.error('Une erreur est survenue lors de l\'envoi de l\'attestation !');
      setIsLoading(false);
      setOpenCommonDialog(false);
      setShowCheckUserDataAlert(true);
    }
  };

  const sendSwarnStatement = async (id) => {
    try {
      setIsLoading(true);
      const response = await customerApi.sendSwarnStatement(id, localStorage.getItem('accessToken'));
      console.clear();
      if (response.status === 500) {
        toast.error('Une erreur est survenue lors de l\'envoi de l\'attestation !');
      } else if (response.status === 400) {
        toast.error(response.errorMessage);
      } else {
        toast.success('Attestation sur l\'honneur envoyée avec succès !');
        getInfoFactu();
      }
      setIsLoading(false);
      setOpenCommonDialog(false);
    } catch (err) {
      console.clear();
      console.error(err);
      toast.error('Une erreur est survenue lors de l\'envoi de l\'attestation !');
      setIsLoading(false);
      setOpenCommonDialog(false);
      setShowCheckUserDataAlert(true);
    }
  };

  const sendMultipleAgreements = async () => {
    try {
      setIsLoading(true);
      const selectedRowsIDs = selectedRows.map((row) => row.id);
      const response = await customerApi.sendMultipleAgreements(selectedRowsIDs, localStorage.getItem('accessToken'));
      console.clear();
      if (response.status === 500) {
        toast.error('Une erreur est survenue lors de l\'envoi de l\'attestation !');
      } else if (response.status === 400) {
        toast.error(response.errorMessage);
      } else {
        toast.success('Attestation sur l\'honneur envoyée avec succès !');
        getInfoFactu();
        setSelectedRows([]);
      }
      setIsLoading(false);
      setOpenCommonDialog(false);
    } catch (err) {
      console.clear();
      console.error(err);
      toast.error('Une erreur est survenue lors de l\'envoi de l\'attestation !');
      setIsLoading(false);
      setOpenCommonDialog(false);
      setShowCheckUserDataAlert(true);
    }
  };

  const sendMultipleSwarnStatements = async () => {
    try {
      setIsLoading(true);
      const selectedRowsIDs = selectedRows.map((row) => row.id);
      const response = await customerApi.sendMultipleSwarnStatements(selectedRowsIDs, localStorage.getItem('accessToken'));
      console.clear();
      if (response.status === 500) {
        toast.error('Une erreur est survenue lors de l\'envoi de l\'attestation !');
      } else if (response.status === 400) {
        toast.error(response.errorMessage);
      } else {
        toast.success('Attestation sur l\'honneur envoyée avec succès !');
        getInfoFactu();
        setSelectedRows([]);
      }
      setIsLoading(false);
      setOpenCommonDialog(false);
    } catch (err) {
      console.clear();
      console.error(err);
      toast.error('Une erreur est survenue lors de l\'envoi de l\'attestation !');
      setIsLoading(false);
      setOpenCommonDialog(false);
      setShowCheckUserDataAlert(true);
    }
  };

  const handleTooltipClose = () => {
    setOpenTooltip(false);
  };

  const handleTooltipOpen = () => {
    setOpenTooltip(true);
  };

  // eslint-disable-next-line consistent-return
  const hasCompletedProfile = (row) => {
    if (row.adeli_rpps === '' || row.email === '' || row.firstname === '' || row.lastname === '' || row.profession === '' || row.civilite === '' || row.adress === '' || row.city === '' || row.zip === '') return false;
    return true;
  };

  const [errors, setErrors] = useState([]);
  const [openErrors, setOpenErrors] = useState(false);

  const getInfoFactu = useCallback(async () => {
    try {
      const data = await sessionApi.getInfoFactu(sessionId, localStorage.getItem('accessToken'));
      if (mounted.current && data.status !== 400) {
        setRows(data.data.apprenants.map((customer) => ({
          id: customer.id,
          customers_id: customer.customers_id,
          firstname: customer.firstname,
          lastname: customer.lastname,
          profession: customer.profession,
          phone: customer.phone,
          primary_connection: moment(customer.primary_connection).format('DD/MM/YYYY HH:mm:ss'),
          last_connection: moment(customer.last_connection).format('DD/MM/YYYY HH:mm:ss'),
          real_price: customer.real_price,
          progression: customer.progression,
          attest_dpc: customer.attest_dpc,
          attest_honneur: customer.attest_honneur,
          attest_user: customer.attest_user,
          agreement_sent: customer.agreement_sent,
          agreement_completed: customer.agreement_completed,
          honor_sent: customer.honor_sent,
          honor_completed: customer.honor_completed,
          certificate_sent: customer.certificate_sent
        })));
        let tempTotal = 0;
        data.data.apprenants.forEach((apprenant) => {
          tempTotal += apprenant.progression === 100 ? apprenant.real_price : 0;
        });
        setTotal(tempTotal);
        setInfoFactu(data.data.info_factu);
        setHonneur(data.data.honneur);
        setUsers(data.data.users);
        // const tempCustomersId = [];
        // data.data.apprenants.forEach((customer) => { tempCustomersId.push(customer.customers_id); });
        setCustomers(data.data.apprenants);
        // console.log(tempCustomersId);
      }
    } catch (err) {
      console.error(err);
      // navigate('/404', { replace: true });
    }
  }, [mounted]);

  useEffect(() => {
    getInfoFactu();
  }, [getInfoFactu]);

  const changeAttesCustomer = useCallback(
    async (id, value, field) => {
      try {
        const values = { id, value, field };
        await sessionApi.changeAttesCustomer(values, localStorage.getItem('accessToken'));
      } catch (err) {
        console.error(err);
        toast.error('Il y a eu un souci lors de la modification !');
      }
    }
  );

  const changeFactuSession = useCallback(
    async (value, field) => {
      try {
        const values = { id: sessionId, value, field, userId: user.id };
        await sessionApi.changeFactuSession(values, localStorage.getItem('accessToken'));
      } catch (err) {
        console.error(err);
        toast.error('Il y a eu un souci lors de la modification !');
      }
    }
  );

  // eslint-disable-next-line consistent-return
  const handleLogs = async (learnerSessionId, returnFileForZip) => {
    try {
      const logs = await sessionApi.getAttestationLogs(learnerSessionId, localStorage.getItem('accessToken'));
      // if (moment(codecoFirst[0].connexion).unix() < moment(session.start_date).unix()) {
      //   setAlertMessage('L\'apprenant a une date de première connexion antérieure à celle du début de la session.');
      //   setShowAlert(true);
      // }
      // else if (moment(codeco[codeco.length - 1].deconnexion).unix() > moment(session.end_date).unix()) {
      //   setAlertMessage('L\'apprenant a une date de dernière connexion postérieure à celle de la fin de la session.');
      //   setShowAlert(true);
      // }
      // let total = '';
      // let timeDuration = '';

      // Si la duration est à 0 seconde, on additionne la duration de tous les codeco
      // if (logs.data.duration === '0s') {
      //   const totalSeconds = codeco.map((item) => item.duree.split(':').reduce((acc, time) => acc * 60 + parseInt(time, 10), 0)).reduce((acc, duration) => acc + duration, 0);
      //   const hours = Math.floor(totalSeconds / 3600);
      //   const minutes = Math.floor((totalSeconds % 3600) / 60);
      //   const seconds = totalSeconds % 60;
      //   total = `${hours.toString().padStart(2, '0')}:${minutes.toString().padStart(2, '0')}:${seconds.toString().padStart(2, '0')}`;
      //   timeDuration = `${hours}h ${minutes}m ${seconds}s`;
      // } else {
      //   total = logs.data.duration.replace(/d|h|m/g, ':').replace(/ |s/g, '');
      //   timeDuration = logs.data.duration.replaceAll('d', 'j');
      // }
      // const units = logs.data.units.filter((el) => el.length > 0);
      const units = logs.data.units.filter((el) => typeof (el.length) === 'undefined');
      const tableExcel = [];

      units.forEach((element) => {
        tableExcel.push(['Nom', 'Prénom', 'Email du participant', 'ID de la session', 'Nom du module', 'Connexion - Date et heure', 'Déconnexion - Date et heure', 'Temps passé', 'Progression (%)']);
        // On fait attention à bien enlever les virgules dans les noms de modules pour éviter les erreurs lors de l'upload
        element.codeco.forEach((cdc) => tableExcel.push([`${logs.data.lastname}`, `${logs.data.firstname}`, `${logs.data.email}`, `${logs.data.program_number}`, `${cdc.module_name.replaceAll(',', '')}`, `${cdc.connect}`, `${cdc.disconnect}`, `${cdc.duration}`, `${cdc.progression}`]));
        tableExcel.push(['', '', '', '', `${element.expected_time}`, '', '', `${element.total_time}`]);
        tableExcel.push([]);
      });
      // tableExcel.push(["Date d'inscription", `${logs.data.subscribed_at}`]);
      const worksheet = XLSX.utils.aoa_to_sheet(
        tableExcel
      );

      const colNum = XLSX.utils.decode_col('H');
      const fmt = 'h:mm:ss';

      const range = XLSX.utils.decode_range(worksheet['!ref']);
      for (let i = range.s.r + 1; i <= range.e.r; ++i) {
        const ref = XLSX.utils.encode_cell({ r: i, c: colNum });
        if (worksheet[ref] && worksheet[ref].v.includes(':')) {
          worksheet[ref].z = fmt;
        }
      }

      const wb = XLSX.utils.book_new();
      XLSX.utils.book_append_sheet(wb, worksheet, 'Logs');

      if (returnFileForZip) {
        /* generate XLSX file */
        return XLSX.write(wb, { type: 'array', bookType: 'xlsx' });
      }
      /* generate XLSX file and send to client */
      XLSX.writeFile(wb, `User ${logs.data.lastname} ${logs.data.firstname}.xlsx`);
    } catch (err) {
      console.error(err);
      toast.error('Il y a eu un souci lors de la génération de l\'attestation !');
    }
  };

  const generateZipFile = async () => {
    try {
      const zip = new JSZip();
      // Parcours de chaque identifiant et génération du fichier XLSX correspondant
      // eslint-disable-next-line no-restricted-syntax
      for (const customer of customers) {
        // eslint-disable-next-line no-await-in-loop
        const workbook = await handleLogs(customer.id, true);
        // Ajout du fichier XLSX à l'archive zip
        zip.file(`User ${customer.lastname.replace(/\//g, '-')} ${customer.firstname.replace(/\//g, '-')}.xlsx`, workbook, { binary: true });
      }

      // Génération du fichier zip
      const zipContent = await zip.generateAsync({ type: 'blob' });
      const blobUrl = URL.createObjectURL(zipContent);
      // Envoi du fichier zip au client
      saveAs(blobUrl, `logs_${sessionId}_${moment().format('DDMMYYYY')}.zip`);
      // on loggue l'utilisation du bouton
      await logApi.saveLogAllLogs(sessionId, localStorage.getItem('accessToken'));
    } catch (err) {
      console.error(err);
      throw new Error('Une erreur s\'est produite lors de la génération du fichier ZIP.');
    }
  };

  const handleGetAttestationsDPC = async () => {
    try {
      const data = await sessionApi.getAttestationsDPC(sessionId, localStorage.getItem('accessToken'));
      const merger = new PDFMerger();

      await Promise.all(data.data.apprenants.map(async (customer) => {
        const element = await pdf(
          <AttestationPDF
            customer={customer}
            formation={data.data.formation}
            orientations={data.data.orientations}
          />
        ).toBlob();
        await merger.add(element);
      }));

      const mergedPdf = await merger.saveAsBlob();

      const fileURL = URL.createObjectURL(mergedPdf);
      window.open(fileURL, '_blank');
    } catch (err) {
      console.error(err);
      toast.error('Il y a eu un souci lors de la génération des documents !');
    }
  };

  const handleCloseErrors = () => {
    setOpenErrors(false);
  };  
  const handleErrors = () => {
    setOpenErrors(true);
  };

  const handleGetTracabilite = async () => {
    const unitsTable = await sessionApi.getTraca(sessionId, localStorage.getItem('accessToken'));
    // const table = customers.filter((customer) => customer.progression === 100).map((customer) => ({ nom: customer.customerLastname, prenom: customer.customerFirstname, rpps: customer.adeli_rpps, day1: customer.primary_connection, lastDay: customer.last_connection, tempsTotal: customer.duration !== null ? customer.duration.replace('d', 'j') : customer.duration }));
    // const unitsTable = table.sort((a, b) => a.nom.localeCompare(b.nom));
    // console.log(unitsTable.data);

    const worksheetData = [];
    for (let i = 0; i < unitsTable.data.length; i++) {
      const oneUnit = unitsTable.data[i];
      for (let j = 0; j < oneUnit.length; j++) {
        const tt = oneUnit[j];
        const rowData = [
          [tt.lastname, tt.firstname, tt.email, tt.adeli_rpps, 'oui', tt.first_connection, tt.last_connection, tt.real_duration]
        ];
        worksheetData.push(...rowData);
      }
      if (i < unitsTable.data.length - 1) {
        worksheetData.push([]);
      }
    }

    const worksheet = XLSX.utils.aoa_to_sheet(worksheetData);
    // const end = trueTable.filter((customer) => moment(customer.lastDay).unix() > moment(session.end_date).unix());
    // const start = trueTable.filter((customer) => moment(customer.day1).unix() < moment(session.start_date).unix());
    // let message = '';
    // message += start.length > 0 ? 'Les apprenants qui ont une date de première connexion antérieure à celle du début de la session: \r\n' : '';
    // start.forEach((name) => {
    //   message += `${name.nom} ${name.prenom} \n`;
    // });
    //   <br />;
    //   message += end.length > 0 ? '\n Les apprenants qui ont une date de dernière connexion postérieure à celle de la fin de la session:  \r\n' : '';
    //   end.forEach((name) => {
    //     message += `${name.nom} ${name.prenom} \n`;
    //   });
    //   setAlertMessage(message);
    //   if (message !== '') {
    //     setShowAlert(true);
    //   }
    const wb = XLSX.utils.book_new();
    XLSX.utils.book_append_sheet(wb, worksheet, 'Traçabilité');
    /* generate XLSX file and send to client */
    XLSX.writeFile(wb, `Traçabilité ${formation.formationName.substring(0, 25)} session ${session.number_session}.xlsx`);
    // gestion erreurs
    if (unitsTable.errors.length > 0) {
      setErrors(unitsTable.errors);
      handleErrors();
    }
  };

  const handleCloseDialog = () => {
    setShowAlert(false);
  };

  
  useEffect(() => {
    console.clear();
    console.log(selectedRows);
  }, [selectedRows]);

  const columns = [
    {
      field: 'firstname',
      headerName: 'Prénom',
      renderCell: (cellValues) => (
        <Link
          component={RouterLink}
          to={`/dashboard/customers/${cellValues.row.customers_id}`}
          variant="subtitle2"
        >
          {cellValues.row.firstname}
        </Link>
      ),
      width: 200,
    },
    {
      field: 'lastname',
      headerName: 'Nom',
      renderCell: (cellValues) => (
        <Link
          component={RouterLink}
          to={`/dashboard/customers/${cellValues.row.customers_id}`}
          variant="subtitle2"
        >
          {cellValues.row.lastname}
        </Link>
      ),
      width: 200,
    },
    {
      field: 'phone',
      headerName: 'Téléphone',
      width: 120
    },
    {
      field: 'profession',
      headerName: 'Profession',
      width: 100,
    },
    {
      field: 'primary_connection',
      headerName: 'Première connexion',
      width: 200,
    },
    {
      field: 'last_connection',
      headerName: 'Dernière connexion',
      width: 200,
    },
    {
      field: 'real_price',
      headerName: 'Prix',
      renderCell: (params) => (`${params.value} €`),
      width: 120,
    },
    {
      field: 'attest_dpc',
      headerName: 'Attes DPC',
      renderCell: (cellValues) => (
        <Switch
          edge="end"
          onChange={() => { cellValues.row.attest_dpc = !cellValues.row.attest_dpc; changeAttesCustomer(cellValues.row.id, cellValues.row.attest_dpc, 'attest_dpc'); }}
          defaultChecked={cellValues.row.attest_dpc}
          value={cellValues.row.attest_dpc}
          disabled={cellValues.row.progression !== 100}
        />
      ),
      width: 150,
    },
    {
      field: 'attest_honneur',
      headerName: 'Attes honneur',
      width: 170,
      renderCell: (cellValues) => (
        <Switch
          edge="end"
          onChange={() => { cellValues.row.attest_honneur = !cellValues.row.attest_honneur; changeAttesCustomer(cellValues.row.id, cellValues.row.attest_honneur, 'attest_honneur'); }}
          defaultChecked={cellValues.row.attest_honneur}
          value={cellValues.row.attest_honneur}
          disabled={!honneur}
        />
      ),
    },
    {
      field: 'attest_user',
      headerName: 'Logs',
      width: 120,
      renderCell: (cellValues) => (
        <Switch
          edge="end"
          onChange={() => { cellValues.row.attest_user = !cellValues.row.attest_user; changeAttesCustomer(cellValues.row.id, cellValues.row.attest_user, 'attest_user'); }}
          defaultChecked={cellValues.row.attest_user}
          value={cellValues.row.attest_user}
        />
      ),
    },
    {
      field: 'actions',
      type: 'actions',
      headerName: 'Actions',
      width: 200,
      cellClassName: 'actions',
      getActions: (cellValues) => [
        <>
          {/* Permet de déterminer si le profil de l'apprenant est complet avant envoi d'attestation */}
          <div sx={{ marginRight: '10px' }}>
            {!hasCompletedProfile(cellValues.row) && <AdjustOutlinedIcon sx={{ fontSize: 10, color: 'red' }} />}
          </div>
          <Tooltip title="Convention de formation">
            <GridActionsCellItem
              icon={<SendIcon />}
              onClick={() => {
                setDialogText(!cellValues.row.agreement_sent
                  ? `Vous êtes sur le point d\'envoyer la convention de formation de l\'apprenant ${cellValues.row.firstname} ${cellValues.row.lastname} pour signature. Cliquez sur envoyer pour confirmer.`
                  : `Attention ! Ce document a déjà été envoyé et est en attente de signature.`);
                setDialogAction('send-agreement');
                setOpenCommonDialog(true);
                setCurrentRowLearnerSessionId(cellValues.row.id);
              }}
              label="Envoyer la convention"
              disabled={!hasCompletedProfile(cellValues.row)}
              style={{ color : cellValues.row.agreement_completed
                ? '#7EB627'
                : cellValues.row.agreement_sent ? '#4A86CE' : 'inherit'}}
            />
          </Tooltip>
          <Tooltip title="Attestation de formation">
            <GridActionsCellItem
              icon={<AttachEmailIcon />}
              onClick={() => {
                setDialogText(!cellValues.row.certificate_sent
                    ? `Vous êtes sur le point d\'envoyer l\'attestation de fin de formation de l\'apprenant ${cellValues.row.firstname} ${cellValues.row.lastname} . Cliquez sur envoyer pour confirmer.`
                  : `Attention ! Ce document a déjà été envoyé et est en attente de signature.`);
                setDialogAction('send-certificate');
                setOpenCommonDialog(true);
                setCurrentRowLearnerSessionId(cellValues.row.id);
              }}
              label="Attestation de formation"
              disabled={!hasCompletedProfile(cellValues.row)}
              style={{ color : cellValues.row.certificate_completed
                ? '#7EB627'
                : cellValues.row.certificate_sent ? '#4A86CE' : 'inherit'}}
            />
          </Tooltip>
          <Tooltip title="Attestation sur l'honneur">
            <GridActionsCellItem
              icon={<AssignmentIcon />}
              onClick={() => {
                setDialogText(!cellValues.row.honor_sent 
                    ? `Vous êtes sur le point d\'envoyer l\'attestation sur l\'honneur à l\'apprenant ${cellValues.row.firstname} ${cellValues.row.lastname} . Cliquez sur envoyer pour confirmer.`
                  : `Attention ! Ce document a déjà été envoyé et est en attente de signature.`);
                setDialogAction('send-swarn-statement');
                setOpenCommonDialog(true);
                setCurrentRowLearnerSessionId(cellValues.row.id);
              }}
              label="Attestation sur l'honneur"
              disabled={!hasCompletedProfile(cellValues.row)}
              style={{ color : cellValues.row.honor_completed
                ? '#7EB627'
                : cellValues.row.honor_sent ? '#4A86CE' : 'inherit'}}
            />
          </Tooltip>
          <GridActionsCellItem
            icon={<ListIcon />}
            onClick={() => handleLogs(cellValues.row.id, false)}
            label="Fichier log"
            disabled={(user.id_service !== 0 && user.access_level !== 0)}
          />
        </>
      ],
    }
  ];

  return (
    <>
    <Dialog open={openErrors} onClose={handleCloseErrors}>
        <DialogTitle>Apprenants dont la synthèse est en erreur</DialogTitle>
        <DialogContent>
          {/* <DialogContentText>
            Confirmer le nom et le type de chaque fichier à importer.
          </DialogContentText> */}
          {errors.map((value, _) => (
            <>
              <Typography>{`${value.firstname} ${value.lastname}`}</Typography>
              <br />
            </>
          ))}
        </DialogContent>
        <DialogActions>
          <Button onClick={handleCloseErrors} color="primary">
            Ok
          </Button>
        </DialogActions>
      </Dialog>
      <Typography
        sx={{ mb: 1 }}
        variant="h5"
        component="h2"
      >
        Progression de la facturation
      </Typography>
      <BorderLinearProgress
        sx={{ mb: 2 }}
        variant="determinate"
        value={progFactu}
      />
      <Typography
        sx={{ mb: 1 }}
        variant="h6"
        component="h2"
      >
        {`Total facturable: ${total.toFixed(2)} €`}
      </Typography>
      <Dialog
        open={openCommonDialog}
        onClose={() => setOpenCommonDialog(false)}
      >
        <DialogContent>
          <DialogContentText>
            { dialogText }
          </DialogContentText>
        </DialogContent>
        <DialogActions>
          <Button
            onClick={() => {
              if (dialogAction === 'send-agreement') {
                downloadAgrement(currentRowLearnerSessionId);
              } else if (dialogAction === 'send-certificate') {
                downloadCerificate(currentRowLearnerSessionId);
              } else if (dialogAction === 'send-swarn-statement') {
                downloadSwarnStatement(currentRowLearnerSessionId);
              }
            }}
            color="primary"
            disabled={isLoading}
          >
            Télécharger
          </Button>
          <ClickAwayListener onClickAway={handleTooltipClose}>
            <Tooltip
              onClose={handleTooltipClose}
              open={openTooltip}
              disableFocusListener
              disableHoverListener
              disableTouchListener
              title={(
              <Alert
                icon={false}
                style={{ backgroundColor: theme.palette.mode !== 'light' && 'transparent' }}
                sx={{ margin: 0 }}
              >
                <Grid
                  container
                  spacing={0}
                >
                  <Grid
                    item
                    sx={8}
                  >
                    Attention - Cette action est irréversible !
                  </Grid>
                  <Grid
                    item
                    sx={4}
                  >
                    <Button
                      onClick={() => {
                        if (dialogAction === 'send-agreement') {
                          sendAgreement(currentRowLearnerSessionId);
                        } else if (dialogAction === 'send-certificate') {
                          sendCertificate(currentRowLearnerSessionId);
                        } else if (dialogAction === 'send-swarn-statement') {
                          sendSwarnStatement(currentRowLearnerSessionId);
                        }
                      }}
                      color="secondary"
                      variant="outlined"
                      size="small"
                      sx={{ mt: 1 }}
                      disabled={isLoading}
                    >
                      Confirmer
                    </Button>
                  </Grid>
                </Grid>
              </Alert>
            )}
            >
              <Button 
                color="primary"
                onClick={handleTooltipOpen}>
                Je souhaite envoyer
              </Button>
            </Tooltip>
          </ClickAwayListener>
          <Button
            onClick={() => setOpenCommonDialog(false)}
            color="primary"
          >
            Annuler
          </Button>
          {isLoading && (
          <Box
            sx={{
              display: 'flex',
              justifyContent: 'center'
            }}
          >
            <CircularProgress />
          </Box>
          )}
        </DialogActions>
      </Dialog>

      <Dialog
        open={showAlert}
        onClose={handleCloseDialog}
      >
        <DialogTitle>
          Différence de dates
        </DialogTitle>
        <DialogContent>
          <DialogContentText>
            <span style={{ whiteSpace: 'pre-line' }}>
              {alertMessage}
            </span>
          </DialogContentText>
        </DialogContent>
        <DialogActions>
          <Button
            onClick={handleCloseDialog}
            color="primary"
          >
            Annuler
          </Button>
        </DialogActions>
      </Dialog>
      <Box style={{ display: 'flex', justifyContent: 'flex-end' }}>
        <Button
          color="primary"
          onClick={handleGetAttestationsDPC}
          sx={{ m: 1 }}
          variant="outlined"
          disabled={user.access_level !== 0}
        >
          Générer les attestations DPC
        </Button>
        <Button
          color="primary"
          onClick={handleGetTracabilite}
          sx={{ m: 1 }}
          variant="outlined"
          disabled={user.access_level !== 0}
        >
          Synthèse de suivi des activités non présentielles
        </Button>
        <Button
          color="primary"
          sx={{
            m: 1,
          }}
          variant="outlined"
          onClick={generateZipFile}
        >
          Télécharger les logs
        </Button>
        {
          selectedRows.length > 0 && (
            <>
            <Button
              color="primary"
              onClick={sendMultipleAgreements}
              sx={{ m: 1 }}
              variant="outlined"
              disabled={user.access_level !== 0}
            >
              Conventions
            </Button>
            <Button
              color="primary"
              onClick={sendMultipleSwarnStatements}
              sx={{ m: 1 }}
              variant="outlined"
              disabled={user.access_level !== 0}
            >
              Attestations sur horneur
            </Button>
              {/* <Chip
                icon={<UploadOutlinedIcon />}
                color="primary"
                label="Conventions"
                variant="outlined"
                sx={{
                  mt: 2,
                  mb: 2,
                  ml: 1,
                }}
                onClick={sendMultipleAgreements}
              /> */}
              {/* <Chip
                icon={<UploadOutlinedIcon />}
                color="primary"
                label="Attestations sur horneur"
                variant="outlined"
                sx={{
                  mb: 2,
                  mt: 2,
                  ml: 1,
                }}
                onClick={sendMultipleSwarnStatements}
              /> */}
            </>
          )
        }
      </Box>
      <Card {...other}>
        <Box height={600}>
          <DataGrid
            rows={rows}
            columns={columns}
            pageSize={100}
            checkboxSelection
            rowsPerPageOptions={[5]}
            onSelectionModelChange={(ids) => {
              const selectedIDs = new Set(ids);
              const _selectedRows = rows.filter((row) =>
                selectedIDs.has(row.id),
              );
              setSelectedRows(_selectedRows);
            }}
          />
        </Box>
      </Card>
      <Grid
        container
        justifyContent="space-between"
        spacing={3}
      >
        <Grid
          item
          md={7}
          xs={12}
        >
          <Card
            {...other}
            sx={{ mt: 5 }}
          >
            <CardHeader title="Checklist" />
            <Box
              sx={{ mt: 2, ml: 2 }}
            >
              <Table>
                <TableHead>
                  <TableRow>
                    <TableCell>
                      Type de document
                    </TableCell>
                    <TableCell>
                      Édité
                    </TableCell>
                    <TableCell>
                      Vérifié
                    </TableCell>
                  </TableRow>
                </TableHead>
                <TableBody>
                  <TableRow>
                    <TableCell>
                      <Typography
                        variant="h6"
                      >
                        Facture
                      </Typography>
                    </TableCell>
                    <TableCell>
                      <Switch
                        edge="end"
                        onClick={(event) => { setInfoFactu((prevState) => ({ ...prevState, factu_edited: !infoFactu.factu_edited })); changeFactuSession(event.target.checked, 'factu_edited'); }}
                        checked={infoFactu.factu_edited}
                        value={infoFactu.factu_edited}
                        disabled={infoFactu.factu_edited && user.id_service !== 0}
                      />
                      <Typography>
                        {infoFactu.source_factu_edited !== null
                          ? `${users.length !== 0 ? users.find((us) => us.id === infoFactu.source_factu_edited).firstname : ''} ${users.length !== 0 ? users.find((us) => us.id === infoFactu.source_factu_edited).lastname : ''} le ${moment(infoFactu.date_factu_edited).format('DD/MM/YYYY HH:mm:ss')}`
                          : ''}
                      </Typography>
                    </TableCell>
                    <TableCell>
                      <Switch
                        edge="end"
                        onClick={(event) => { setInfoFactu((prevState) => ({ ...prevState, factu_verified: !infoFactu.factu_verified })); changeFactuSession(event.target.checked, 'factu_verified'); }}
                        checked={infoFactu.factu_verified}
                        value={infoFactu.factu_verified}
                        disabled={infoFactu.factu_verified && user.id_service !== 0}
                      />
                      <Typography>
                        {infoFactu.source_factu_verified !== null
                          ? `${users.length !== 0 ? users.find((us) => us.id === infoFactu.source_factu_verified).firstname : ''} ${users.length !== 0 ? users.find((us) => us.id === infoFactu.source_factu_verified).lastname : ''} le ${moment(infoFactu.date_factu_verified).format('DD/MM/YYYY HH:mm:ss')}`
                          : ''}
                      </Typography>
                    </TableCell>
                  </TableRow>
                  <TableRow>
                    <TableCell>
                      <Typography
                        variant="h6"
                      >
                        Attestations DPC
                      </Typography>
                    </TableCell>
                    <TableCell>
                      <Switch
                        edge="end"
                        onClick={(event) => { setInfoFactu((prevState) => ({ ...prevState, attestation_edited: !infoFactu.attestation_edited })); changeFactuSession(event.target.checked, 'attestation_edited'); }}
                        checked={infoFactu.attestation_edited}
                        value={infoFactu.attestation_edited}
                        disabled={infoFactu.attestation_edited && user.id_service !== 0}
                      />
                      <Typography>
                        {infoFactu.source_attestation_edited !== null
                          ? `${users.length !== 0 ? users.find((us) => us.id === infoFactu.source_attestation_edited).firstname : ''} ${users.length !== 0 ? users.find((us) => us.id === infoFactu.source_attestation_edited).lastname : ''} le ${moment(infoFactu.date_attestation_edited).format('DD/MM/YYYY HH:mm:ss')}`
                          : ''}
                      </Typography>
                    </TableCell>
                    <TableCell>
                      <Switch
                        edge="end"
                        onClick={(event) => { setInfoFactu((prevState) => ({ ...prevState, attestation_verified: !infoFactu.attestation_verified })); changeFactuSession(event.target.checked, 'attestation_verified'); }}
                        checked={infoFactu.attestation_verified}
                        value={infoFactu.attestation_verified}
                        disabled={infoFactu.attestation_verified && user.id_service !== 0}
                      />
                      <Typography>
                        {infoFactu.source_attestation_verified !== null
                          ? `${users.length !== 0 ? users.find((us) => us.id === infoFactu.source_attestation_verified).firstname : ''} ${users.length !== 0 ? users.find((us) => us.id === infoFactu.source_attestation_verified).lastname : ''} le ${moment(infoFactu.date_attestation_verified).format('DD/MM/YYYY HH:mm:ss')}`
                          : ''}
                      </Typography>
                    </TableCell>
                  </TableRow>
                  <TableRow>
                    <TableCell>
                      <Typography
                        variant="h6"
                      >
                        Logs
                      </Typography>
                    </TableCell>
                    <TableCell>
                      <Switch
                        edge="end"
                        onClick={(event) => { setInfoFactu((prevState) => ({ ...prevState, logs_edited: !infoFactu.logs_edited })); changeFactuSession(event.target.checked, 'logs_edited'); }}
                        checked={infoFactu.logs_edited}
                        disabled={infoFactu.logs_edited && user.id_service !== 0}
                      />
                      <Typography>
                        {infoFactu.source_logs_edited !== null
                          ? `${users.length !== 0 ? users.find((us) => us.id === infoFactu.source_logs_edited).firstname : ''} ${users.length !== 0 ? users.find((us) => us.id === infoFactu.source_logs_edited).lastname : ''} le ${moment(infoFactu.date_logs_edited).format('DD/MM/YYYY HH:mm:ss')}`
                          : ''}
                      </Typography>
                    </TableCell>
                    <TableCell>
                      <Switch
                        edge="end"
                        onClick={(event) => { setInfoFactu((prevState) => ({ ...prevState, logs_verified: !infoFactu.logs_verified })); changeFactuSession(event.target.checked, 'logs_verified'); }}
                        checked={infoFactu.logs_verified}
                        disabled={infoFactu.logs_verified && user.id_service !== 0}
                      />
                      <Typography>
                        {infoFactu.source_logs_verified !== null
                          ? `${users.length !== 0 ? users.find((us) => us.id === infoFactu.source_logs_verified).firstname : ''} ${users.length !== 0 ? users.find((us) => us.id === infoFactu.source_logs_verified).lastname : ''} le ${moment(infoFactu.date_logs_verified).format('DD/MM/YYYY HH:mm:ss')}`
                          : ''}
                      </Typography>
                    </TableCell>
                  </TableRow>
                  <TableRow>
                    <TableCell>
                      <Typography
                        variant="h6"
                      >
                        Traçabilité
                      </Typography>
                    </TableCell>
                    <TableCell>
                      <Switch
                        edge="end"
                        onClick={(event) => { setInfoFactu((prevState) => ({ ...prevState, traca_edited: !infoFactu.traca_edited })); changeFactuSession(event.target.checked, 'traca_edited'); }}
                        checked={infoFactu.traca_edited}
                        disabled={infoFactu.traca_edited && user.id_service !== 0}
                      />
                      <Typography>
                        {infoFactu.source_traca_edited !== null
                          ? `${users.length !== 0 ? users.find((us) => us.id === infoFactu.source_traca_edited).firstname : ''} ${users.length !== 0 ? users.find((us) => us.id === infoFactu.source_traca_edited).lastname : ''} le ${moment(infoFactu.date_traca_edited).format('DD/MM/YYYY HH:mm:ss')}`
                          : ''}
                      </Typography>
                    </TableCell>
                    <TableCell>
                      <Switch
                        edge="end"
                        onClick={(event) => { setInfoFactu((prevState) => ({ ...prevState, traca_verified: !infoFactu.traca_verified })); changeFactuSession(event.target.checked, 'traca_verified'); }}
                        checked={infoFactu.traca_verified}
                        value={infoFactu.traca_verified}
                        disabled={infoFactu.traca_verified && user.id_service !== 0}
                      />
                      <Typography>
                        {infoFactu.source_traca_verified !== null
                          ? `${users.length !== 0 ? users.find((us) => us.id === infoFactu.source_traca_verified).firstname : ''} ${users.length !== 0 ? users.find((us) => us.id === infoFactu.source_traca_verified).lastname : ''} le ${moment(infoFactu.date_traca_verified).format('DD/MM/YYYY HH:mm:ss')}`
                          : ''}
                      </Typography>
                    </TableCell>
                  </TableRow>
                  <TableRow>
                    <TableCell>
                      <Typography
                        variant="h6"
                      >
                        Attestations sur honneur reçues ?
                      </Typography>
                    </TableCell>
                    <TableCell>
                      <Switch
                        edge="end"
                        onClick={(event) => { setInfoFactu((prevState) => ({ ...prevState, honneur_received: !infoFactu.honneur_received })); changeFactuSession(event.target.checked, 'honneur_received'); }}
                        checked={infoFactu.honneur_received}
                        disabled={!honneur || (infoFactu.honneur_received && user.id_service !== 0)}
                      />
                    </TableCell>
                    <TableCell>
                      <Typography>
                        {infoFactu.source_honneur_received !== null
                          ? `${users.length !== 0 ? users.find((us) => us.id === infoFactu.source_honneur_received).firstname : ''} ${users.length !== 0 ? users.find((us) => us.id === infoFactu.source_honneur_received).lastname : ''} le ${moment(infoFactu.date_honneur_received).format('DD/MM/YYYY HH:mm:ss')}`
                          : ''}
                      </Typography>
                    </TableCell>
                  </TableRow>
                </TableBody>
              </Table>
            </Box>
          </Card>
        </Grid>
        <Grid
          item
          md={5}
          xs={12}
        >
          <Card
            {...other}
            sx={{ mt: 5 }}
          >
            <CardHeader title="Dates de facturation" />
            <Box
              sx={{ mt: 2, ml: 2 }}
            >
              <Table>
                <TableHead>
                  <TableRow>
                    <TableCell>
                      Type de document
                    </TableCell>
                    <TableCell>
                      Date d&apos;envoi
                    </TableCell>
                  </TableRow>
                </TableHead>
                <TableBody>
                  <TableRow>
                    <TableCell>
                      <Typography
                        variant="h6"
                      >
                        Facture
                      </Typography>
                    </TableCell>
                    <TableCell>
                      <LocalizationProvider
                        dateAdapter={DateAdapter}
                      >
                        <MobileDatePicker
                          label="Date d'édition"
                          inputFormat="DD/MM/yyyy"
                          onChange={() => null}
                          onClose={() => null}
                          showTodayButton
                          clearable
                          onAccept={(date) => { setInfoFactu((prevState) => ({ ...prevState, factu: date })); changeFactuSession(date !== null ? moment(date).format('YYYY-MM-DD') : null, 'factu'); }}
                          renderInput={(inputProps) => (
                            <TextField
                              {...inputProps}
                            />
                          )}
                          value={infoFactu.factu}
                        />
                      </LocalizationProvider>
                      <Typography>
                        {infoFactu.source_factu !== null
                          ? `${users.length !== 0 ? users.find((us) => us.id === infoFactu.source_factu).firstname : ''} ${users.length !== 0 ? users.find((us) => us.id === infoFactu.source_factu).lastname : ''} le ${moment(infoFactu.date_factu).format('DD/MM/YYYY HH:mm:ss')}`
                          : ''}
                      </Typography>
                    </TableCell>
                  </TableRow>
                  <TableRow>
                    <TableCell>
                      <Typography
                        variant="h6"
                      >
                        Demande de facturation ANDPC
                      </Typography>
                    </TableCell>
                    <TableCell>
                      <LocalizationProvider
                        dateAdapter={DateAdapter}
                      >
                        <MobileDatePicker
                          label="Date de facturation"
                          inputFormat="DD/MM/yyyy"
                          onChange={() => null}
                          onClose={() => null}
                          showTodayButton
                          clearable
                          onAccept={(date) => { setInfoFactu((prevState) => ({ ...prevState, demande_factu: date })); changeFactuSession(date !== null ? moment(date).format('YYYY-MM-DD') : null, 'demande_factu'); }}
                          renderInput={(inputProps) => (
                            <TextField
                              {...inputProps}
                            />
                          )}
                          value={infoFactu.demande_factu}
                        />
                      </LocalizationProvider>
                      <Typography>
                        {infoFactu.source_demande_factu !== null
                          ? `${users.length !== 0 ? users.find((us) => us.id === infoFactu.source_demande_factu).firstname : ''} ${users.length !== 0 ? users.find((us) => us.id === infoFactu.source_demande_factu).lastname : ''} le ${moment(infoFactu.date_demande_factu).format('DD/MM/YYYY HH:mm:ss')}`
                          : ''}
                      </Typography>
                    </TableCell>
                  </TableRow>
                  <TableRow>
                    <TableCell>
                      <Typography
                        variant="h6"
                      >
                        Affacturage
                      </Typography>
                    </TableCell>
                    <TableCell>
                      <LocalizationProvider
                        dateAdapter={DateAdapter}
                      >
                        <MobileDatePicker
                          label="Date affacturage"
                          inputFormat="DD/MM/yyyy"
                          onChange={() => null}
                          onClose={() => null}
                          showTodayButton
                          clearable
                          onAccept={(date) => { setInfoFactu((prevState) => ({ ...prevState, affacturage: date })); changeFactuSession(date !== null ? moment(date).format('YYYY-MM-DD') : null, 'affacturage'); }}
                          renderInput={(inputProps) => (
                            <TextField
                              {...inputProps}
                            />
                          )}
                          value={infoFactu.affacturage}
                        />
                      </LocalizationProvider>
                      <Typography>
                        {infoFactu.source_affacturage !== null
                          ? `${users.length !== 0 ? users.find((us) => us.id === infoFactu.source_affacturage).firstname : ''} ${users.length !== 0 ? users.find((us) => us.id === infoFactu.source_affacturage).lastname : ''} le ${moment(infoFactu.date_affacturage).format('DD/MM/YYYY HH:mm:ss')}`
                          : ''}
                      </Typography>
                    </TableCell>
                  </TableRow>
                  <TableRow>
                    <TableCell>
                      <Typography
                        variant="h6"
                      >
                        Paiement ANDPC
                      </Typography>
                    </TableCell>
                    <TableCell>
                      <LocalizationProvider
                        dateAdapter={DateAdapter}
                      >
                        <MobileDatePicker
                          label="Date de paiement"
                          inputFormat="DD/MM/yyyy"
                          onChange={() => null}
                          onClose={() => null}
                          showTodayButton
                          clearable
                          onAccept={(date) => { setInfoFactu((prevState) => ({ ...prevState, paiement_andpc: date })); changeFactuSession(date !== null ? moment(date).format('YYYY-MM-DD') : null, 'paiement_andpc'); }}
                          renderInput={(inputProps) => (
                            <TextField
                              {...inputProps}
                            />
                          )}
                          value={infoFactu.paiement_andpc}
                        />
                      </LocalizationProvider>
                      <Typography>
                        {infoFactu.source_paiement_andpc !== null
                          ? `${users.length !== 0 ? users.find((us) => us.id === infoFactu.source_paiement_andpc).firstname : ''} ${users.length !== 0 ? users.find((us) => us.id === infoFactu.source_paiement_andpc).lastname : ''} le ${moment(infoFactu.date_paiement_andpc).format('DD/MM/YYYY HH:mm:ss')}`
                          : ''}
                      </Typography>
                    </TableCell>
                  </TableRow>
                </TableBody>
              </Table>
            </Box>
          </Card>
        </Grid>
      </Grid>
    </>
  );
};

SessionFacturation.propTypes = {
  sessionId: PropTypes.number.isRequired,
  session: PropTypes.object.isRequired,
  formation: PropTypes.object.isRequired,
};

export default SessionFacturation;
